import { FormInput } from '../../../interfaces/form-action-interfaces'
import AceEditor from 'react-ace'

const CodeEditorComponent: React.FC<FormInput> = ({
  title,
  placeholder,
  name,
  type,
  value,
  onChangeCodeEditor,
  size = 12,
  hidden = false,
  required = true,
}) => {
  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <AceEditor
        mode={'java'}
        theme='github'
        placeholder={placeholder}
        onChange={onChangeCodeEditor}
        value={value}
        name={name}
        editorProps={{ $blockScrolling: true }}
      />
      <label className={`d-block fw-semibold fs-6 mb-5`}>Preview</label>
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </div>
  )
}

export default CodeEditorComponent
